<template>
  <div>
    <!-- <v-app-bar app :color="$store.state.appThemeColor" dark dense> -->

    <v-navigation-drawer
      app
      v-model="drawer"
      left
    >
      <v-list-item dense>
        <v-list-item-avatar size="30" @click="() => { if($route.name != 'Account') $router.push({name: 'Account'}) }" style="cursor: pointer;">
          <v-img
            :src="$store.state.loginInfo.profileImage"
            min-width="31"
            min-height="31"
          ></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title @click="() => { if($route.name != 'Account') $router.push({name: 'Account'}) }" style="cursor: pointer;">{{
            $store.state.loginInfo.firstName +
            " " +
            $store.state.loginInfo.lastName
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
            $store.state.loginInfo.email
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <MenuStudent></MenuStudent>
      <v-btn
        @click.stop="drawer = !drawer"
        color="primary"
        icon
        class="float-right flex-end"
        rounded
        outlined
        fixed
        style="bottom: 30px; right: 12px"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-navigation-drawer>
    <router-view :key="$route.fullPath">
      <v-app-bar-nav-icon
        slot="appDrawer"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </router-view>
    <v-btn
      v-if="!showBottomNav"
      @click.stop="drawer = !drawer"
      color="primary"
      icon
      class="float-left flex-end"
      rounded
      outlined
      fixed
      style="bottom: 30px; left: 12px"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    <bottom-nav v-if="showBottomNav"></bottom-nav>
    <LoadingDialog v-if="$store.state.showLoadingDialog" />
    <PageOverlay v-if="$store.state.showPageOverlay" />
  </div>
</template>

<script>
import LoadingDialog from "@/components/LoadingDialog.vue";
import PageOverlay from "@/components/PageOverlay.vue";
import BottomNav from "./BottomNav.vue";
import MenuStudent from "./MenuStudent.vue";
export default {
  components: {
    BottomNav,
    MenuStudent,
    LoadingDialog,
    PageOverlay,
  },

  computed: {
    showBottomNav() {
      // console.log(this.$vuetify.breakpoint.name);
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        case "lg":
          return false;
        case "xl":
          return false;
      }
      return true;
    },
  },

  data: () => ({
    drawer: null,
  }),

  methods: {
    setDrawer() {
      this.drawer = !this.drawer;
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 800px !important;
}

@import url("https://fonts.googleapis.com/css?family=Ubuntu");

#awn-toast-container *,
#awn-popup-wrapper {
  font-family: "Ubuntu" !important;
}
</style>
