<template>
  <v-list dense nav>
    <div v-for="item in items" :key="item.title">
      <v-divider v-if="item.title == 'Divider'"></v-divider>

      <v-list-item
        v-if="item.title != 'Divider' && item.subitems.length == 0"
        :to="item.to"
        @click="$store.state.showNavbar = !$store.state.showNavbar"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group
        v-if="item.title != 'Divider' && item.subitems.length > 0"
        :value="groupValue(item.subitems)"
        :prepend-icon="item.icon"
      >
        <template v-slot:activator>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </template>
        <v-list-item
          v-for="(subitem, i) in item.subitems"
          :key="i"
          link
          :to="subitem.to"
          @click="$store.state.showNavbar = !$store.state.showNavbar"
          class="pl-8"
        >
          <v-list-item-icon>
            <v-icon>{{ subitem.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-text="subitem.title"></v-list-item-title>
        </v-list-item>
      </v-list-group>
    </div>
  </v-list>
</template>

<script>
export default {
  components: {},

  computed: {
    showBottomNav() {
      // console.log(this.$vuetify.breakpoint.name);
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        case "lg":
          return false;
        case "xl":
          return false;
      }
      return true;
    },
  },

  data: () => ({
    items: [
      {
        title: "Home",
        icon: "mdi-view-dashboard",
        to: { name: "Home" },
        subitems: [],
      },
      // {
      //   title: "Divider",
      //   subitems: [],
      // },
      {
        title: "Kelas",
        icon: "mdi-home-city",
        to: { name: "Home" },
        subitems: [
          {
            title: "Teman Sekelas",
            icon: "mdi-human",
            to: { name: "MyFriends" },
          },
          {
            title: "Daftar Guru",
            icon: "mdi-human",
            to: { name: "TeacherList" },
          },
          {
            title: "Daftar Pelajaran",
            icon: "mdi-book",
            to: { name: "SubjectsList" },
          },
          {
            title: "Jadwal Pelajaran",
            icon: "mdi-book",
            to: { name: "SubjectsSchedule" },
          },
        ],
      },
      {
        title: "Kehadiran",
        icon: "mdi-account-check",
        to: { name: "Attendance" },
        subitems: [],
      },
      {
        title: "Ujian",
        icon: "mdi-pen",
        to: { name: "Exam" },
        subitems: [],
      },
      {
        title: "Nilai",
        icon: "mdi-book-open",
        to: { name: "Scores" },
        subitems: [],
      },
      {
        title: "Pengumuman",
        icon: "mdi-bullhorn",
        to: { name: "Announcement" },
        subitems: [],
      },
      {
        title: "Agenda",
        icon: "mdi-calendar-clock",
        to: { name: "AgendaList" },
        subitems: [],
      },
      {
        title: "E-Perpus",
        icon: "mdi-book-open-page-variant",
        to: { name: "Elibrary" },
        subitems: [],
      },
      {
        title: "About",
        icon: "mdi-information",
        to: { name: "About" },
        subitems: [],
      },
    ],
    drawer: null,
  }),

  methods: {
    groupValue(childs) {
      var rName = this.$route.name;
      for (var i = 0; i < childs.length; i++) {
        if (childs[i].to.name == rName) return true;
      }

      return false;
    },

    setDrawer() {
      this.drawer = !this.drawer;
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 800px !important;
}

@import url("https://fonts.googleapis.com/css?family=Ubuntu");

#awn-toast-container *,
#awn-popup-wrapper {
  font-family: "Ubuntu" !important;
}
</style>
