<template>
  <default-layout>
    <template slot="toolbar">
      <slot name="appDrawer"></slot>
      <v-toolbar-title class="pl-1">
        <v-icon v-if="$store.state.pageIcon" class="pb-1 pr-2">{{
          $store.state.pageIcon
        }}</v-icon>
        <span>{{ $store.state.pageTitle }}</span>
      </v-toolbar-title>
    </template>

    <v-container>
      <!-- <h1>Selamat Datang, {{ $store.state.loginInfo.firstName }}</h1> -->
      <div class="my-4">
        <v-row>
          <v-col cols="3">
            <div class="text-center">
              <v-btn
                color="teal darken-3"
                dark
                class="mx-auto"
                fab
                :to="{ name: 'MyFriends' }"
              >
                <v-icon dark> mdi-human </v-icon>
              </v-btn>
              <p class="text-center mx-auto">Teman Sekelas</p>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="text-center">
              <v-btn
                color="teal darken-3"
                dark
                class="mx-auto"
                fab
                :to="{ name: 'TeacherList' }"
              >
                <v-icon dark> mdi-human-greeting-variant </v-icon>
              </v-btn>
              <p class="text-center mx-auto">Daftar Guru</p>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="text-center">
              <v-btn
                color="teal darken-3"
                dark
                class="mx-auto"
                fab
                :to="{ name: 'SubjectsList' }"
              >
                <v-icon dark> mdi-book </v-icon>
              </v-btn>
              <p class="text-center mx-auto">Mata Pelajaran</p>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="text-center">
              <v-btn
                color="teal darken-3"
                dark
                class="mx-auto"
                fab
                :to="{ name: 'SubjectsSchedule' }"
              >
                <v-icon dark> mdi-calendar-multiple-check </v-icon>
              </v-btn>
              <p class="text-center mx-auto">Jadwal Pelajaran</p>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <div class="text-center">
              <v-btn
                color="teal darken-3"
                dark
                class="mx-auto"
                fab
                :to="{ name: 'AgendaList' }"
              >
                <v-icon dark> mdi-calendar-clock </v-icon>
              </v-btn>
              <p class="text-center mx-auto">Agenda</p>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="my-2">
        <h3 class="my-4">Pengumuman Terbaru</h3>

        <v-card
          class="my-2"
          outlined
          elevation="4"
          v-for="(announcement, i) in announcements"
          :key="i"
          @click="readAnnouncement(announcement.id)"
        >
          <v-card-title v-text="announcement.title"></v-card-title>
          <v-card-subtitle v-text="announcement.publish_date"></v-card-subtitle>
          <v-card-text v-text="announcement.short_body"></v-card-text>
        </v-card>
      </div>
      <div class="my-2">
        <v-row dense>
          <v-col class="d-flex justify-center my-4">
            <v-btn v-if="found == true && foundAnn == limit" @click="loadMore">Load more..</v-btn>
            <v-btn link :to="{ name: 'Announcement' }">Lihat Semua</v-btn>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";
export default {
  name: "Home",
  components: { DefaultLayout },
  data: () => ({
    announcements: [],
    page: 1,
    found: null,
    foundAnn: null,
    limit: 0,
  }),
  methods: {
    getListAnnouncements() {
      this.loading = true;
      this.found = false;
      this.foundAnn = 0;
      var data = {
        params: {
          page: this.page,
        },
      };

      if (this.page == 1) {
        this.announcements = [];
      }
      this.$axios.get("announcement/new", data).then((res) => {
        this.$store.state.showLoadingDialog = false;
        if (res.data.status == "success") {
          this.announcements = res.data.data.announcements;
          this.limit = res.data.data.limit
          this.foundAnn = res.data.data.found_announcements
          let _this = this;
          if (res.data.data.announcements.length > 0) {
            res.data.data.announcements.forEach(function (announcement) {
              _this.announcements.push(announcement);
            });
            this.found = true;
          } else {
            this.found = false;
          }
          this.loading = false;
        } else {
          this.announcements = [];
        }
      });
      this.loading = false;
    },
    readAnnouncement(id) {
      this.$router.push("/announcement/read/" + id);
    },
    loadMore() {
      this.page = this.page + 1;
      this.getListAnnouncements();
    },
  },
  beforeMount() {
    if (this.$store.state.isLoggedIn == false) {
      this.$router.push("/login");
    }
  },
  mounted() {
    this.getListAnnouncements();
    this.setPageTitle("Home", "mdi-home");
  },
};
</script>
